@use 'styles/theme/theme' as theme;

.drawerRootArtist {
  min-height: 590px;
  max-height: 590px;

  @media screen and (max-width: 1280px) {
    min-height: 90%;
    max-height: 90%;
  }
}

.drawerRootCasting {
  min-height: 569px;
  max-height: 569px;

  @media screen and (max-width: 1280px) {
    height: 90%;
    max-height: 90%;
  }
}

.bannerDrawer {
  display: flex;
  height: 569px;

  &__artist {
    height: 590px;
  }
  &__left {
    width: 510px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: theme.$orangeLight;
  }

  &__leftTitle {
    margin-left: 75px;
  }

  &__right {
    width: calc(100% - 510px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid theme.$borderGrey;
  }

  &__logoSubText {
    margin-left: 4px;
  }

  &__listWorks {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    position: relative;
    li {
      list-style: none;
      margin-right: 3px;
      position: relative;
      padding-left: 6px;

      &:first-child {
        padding-left: 0;
      }

      &:not(:first-child)::before {
        content: '•';
        position: absolute;
        left: 0;
        top: 50%;
        bottom: -5px;
        transform: translateY(-50%);
        font-size: 10px;
        color: theme.$orange;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 25px 40px;
  }

  &__mainTop {
    border-bottom: 1px solid theme.$borderGrey;
    padding-bottom: 30px;
  }

  &__mainBottom {
    padding-top: 30px;
  }

  &__footer {
    display: flex;
    padding: 16px 24px;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid theme.$tertiaryGrey;

    @media screen and (max-width: 1280px) {
      justify-content: center;
    }

    @media screen and (max-width: 1280px) {
      border-top: none;
      padding: 0 16px;
      position: sticky;
      top: 80px;
      z-index: 1;
    }
  }

  &__artistFeatures {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @media screen and (max-width: 1280px) {
      row-gap: 12px;
      margin-top: 12px;
    }
  }

  &__castingFeatures {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media screen and (max-width: 1280px) {
      row-gap: 12px;
      margin-top: 12px;
      justify-content: center;
    }
  }

  &__castingFeatureItem {
    width: 35%;

    @media screen and (max-width: 1280px) {
      display: flex;
      width: 45%;
    }
  }

  &__artistFeatureItem {
    @media screen and (max-width: 1280px) {
      display: flex;
    }
  }

  &__featureIcon {
    margin-right: 6px;
    fill: theme.$orange;
    flex-shrink: 0;
  }

  &__footerBtn {
    min-width: 240px;

    @media screen and (max-width: 1280px) {
      width: 400px;
    }

    @media screen and (max-width: 600px) {
      min-width: 100%;
    }
  }
}

.trustedByBlock {
  margin: 18px 0;

  p {
    font-size: 11px;
    font-weight: 400;
    text-transform: capitalize;
    color: theme.$secondaryGrey;
  }
}

.stats {
  display: flex;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 1280px) {
    padding: 0 16px;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
    padding-top: 20px;
    border-top: 1px solid theme.$borderGrey;
    margin-top: 20px;
  }

  &__item {
    margin-left: 24px;
    padding-left: 6px;
    white-space: nowrap;

    &:first-child {
      margin: 0;
      padding: 0;
      &::marker {
        content: '';
      }
    }
    &::marker {
      color: theme.$tertiaryGrey;
    }
  }

  &__number {
    margin-right: 2px;
  }
}

.scrollLeftIndicator {
  box-shadow: 10px 0px 8px -6px rgba(0, 0, 0, 0.3);
  border-right: transparent;

  svg {
    margin-left: 7px;
  }
}

.scrollRightIndicator {
  border-left: transparent;
  box-shadow: -13px 0px 8px -6px rgba(0, 0, 0, 0.3);
  svg {
    margin-right: 7px;
  }
}

.bannerMobileDrawer {
  &__header {
    display: flex;
    padding: 16px;
    justify-content: center;
    border-bottom: 1px solid theme.$borderGrey;
    width: 100%;
    position: sticky;
    top: 0;
    background: theme.$white;
    z-index: 1;
  }
  &__headerContent {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 16px;

    svg {
      fill: theme.$primaryGrey !important;
    }
  }
  &__main {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__image {
    margin-top: 20px;
  }
  &__features {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 28px;
    margin-bottom: 21px;
    padding: 0 16px;
  }
  &__featuresArtist {
    margin-bottom: 40px;
  }
  &__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 16px;
    margin-top: 28px;
    border-radius: 4px;
    border: 1px solid theme.$whiteSmoke;
  }
  &__customerLogo {
    gap: 8px;
    display: flex;
    margin-top: 12px;
    text-align: center;
    flex-direction: column;
    width: 100%;
  }
}

.trustedByStrip {
  border-bottom: 1px solid theme.$whiteSmoke;
  padding: 12px 14px 12px 0px;
}

.testimonialContainer {
  > div {
    gap: 0;
    > div {
      padding: 16px 6px;
      border-bottom: 1px solid theme.$whiteSmoke;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
