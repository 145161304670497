@use 'styles/theme/theme' as theme;

.banner {
  position: relative;
  background: theme.$orangeLight;
  justify-content: space-around;
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 240px;
  @media screen and (max-width: 600px) {
    padding: 20px 40px !important;
  }

  @media screen and (max-width: 960px) {
    height: 230px;
  }

  &__logoBlock {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    padding: 12px;
    z-index: 2;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__bgBlurredShade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 489.277px;
    transform: translate(50%, 0%);
    background: #fff;
    filter: blur(69.1500015258789px);
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__disclaimer {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: theme.$grey14;
  }

  &__image {
    z-index: 1;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__textArea {
    z-index: 1;
    align-self: center;
    @media screen and (max-width: 1024px) {
      text-align: left;
    }
    @media screen and (max-width: 900px) {
      text-align: center;
    }
    &_title {
      margin-bottom: 20px;
      position: relative;
      font-family: 'Playfair Display';
      @media screen and (max-width: 1024px) and (min-width: 900px) {
        text-transform: lowercase !important;
        &::first-letter {
          text-transform: uppercase !important;
        }
      }
      @media screen and (max-width: 1024px) {
        font-size: 32px !important;
        margin-bottom: 22px;
      }
    }
    &_subTitle {
      letter-spacing: 3px;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        width: 100% !important;
      }
      @media screen and (max-width: 1024px) {
        font-size: 16px !important;
        max-width: 400px;
      }
      @media screen and (max-width: 900px) {
        font-size: 12px !important;
      }
    }
    &_otherText {
      font-family: 'Playfair Display';
      @media screen and (max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  .button {
    margin-top: 7px;
    padding: 13px 12px 13px 0px;
  }
}

.bannerReverse {
  flex-direction: row-reverse;
}

.bannerOne {
  padding: 0 124px 0 75px;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
  .banner {
    &__textArea {
      &_title {
        font-size: 46px;
      }
      &_subTitle {
        font-size: 24px;
        color: theme.$orange;
        font-weight: theme.$font-weight-medium;
      }
    }
  }
}

.bannerTwo {
  padding: 0 80px;
  justify-content: space-between;
  gap: 60px;
  .banner {
    &__image {
      width: 30%;
      picture {
        margin-top: 11px;
        display: flex;
        justify-content: flex-end;
      }
    }
    &__textArea {
      flex: 1;
      &_title {
        font-size: 64px;
        span {
          vertical-align: super;
          font-size: 29px;
          margin-left: -10px;
          @media screen and (max-width: 1024px) {
            font-size: 18px;
            margin-left: -5px;
          }
        }
      }
      &_subTitle {
        color: theme.$orange;
        font-size: 20px;
        font-weight: theme.$font-weight-medium;
      }
      &_otherText {
        font-size: 36px;
      }
    }
  }
}

.bannerThree {
  padding: 0 80px;
  gap: 30px;
  .banner {
    &__image {
      picture {
        margin-top: 19px;
      }
    }
    &__textArea {
      flex: 1;
      &_title {
        font-size: 52px;
        span {
          vertical-align: super;
          font-size: 29px;
          margin-left: -10px;
          @media screen and (max-width: 1024px) {
            font-size: 18px;
            margin-left: -5px;
          }
        }
      }
      &_subTitle {
        color: theme.$orange;
        font-size: 20px;
        font-weight: theme.$font-weight-medium;
      }
      &_otherText {
        font-size: 36px;
      }
    }
  }
}

.bannerFour,
.bannerFive {
  padding: 0 100px 0 80px;
  .banner {
    &__image {
      picture {
        margin-top: 23px;
      }
    }
    &__textArea {
      &_title {
        font-size: 52px;
        @media screen and (max-width: 1280px) {
          font-size: 42px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 32px;
        }
      }
      &_subTitle {
        font-size: 18px;
        color: theme.$grey12;
        font-weight: theme.$font-weight-medium;
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
      &_otherText {
        font-size: 36px;
        color: theme.$orange;
        @media screen and (max-width: 1280px) {
          font-size: 20px;
        }
      }
    }
  }
}

.bannerFive {
  background: theme.$grey13;

  .banner {
    &__bgBlurredShade {
      display: none;
    }
    &__textArea {
      &_title {
        color: theme.$white;
      }
      &_subTitle {
        color: theme.$orange;
      }
      .button {
        color: theme.$white;
        svg {
          fill: theme.$white;
        }
      }
    }
  }
}

.bannerSix {
  padding: 0 90px 0 70px;
  @media screen and (max-width: 1280px) {
    padding: 0 80px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  .banner {
    &__image {
      picture {
        margin-top: 38px;
      }
    }
    &__textArea {
      &_title {
        font-family: Roboto;
        @media screen and (max-width: 1280px) {
          font-size: 32px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }
      }
      &_subTitle {
        font-family: Roboto;
        color: theme.$grey12;
        font-weight: theme.$font-weight-medium;
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
      }
      &_otherText {
        font-family: Roboto;
        @media screen and (max-width: 1280px) {
          font-size: 20px;
        }
      }
    }
  }
}
